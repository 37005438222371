// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aarp-js": () => import("../src/pages/aarp.js" /* webpackChunkName: "component---src-pages-aarp-js" */),
  "component---src-pages-blue-foundry-bank-js": () => import("../src/pages/blue-foundry-bank.js" /* webpackChunkName: "component---src-pages-blue-foundry-bank-js" */),
  "component---src-pages-curacao-js": () => import("../src/pages/curacao.js" /* webpackChunkName: "component---src-pages-curacao-js" */),
  "component---src-pages-hp-js": () => import("../src/pages/hp.js" /* webpackChunkName: "component---src-pages-hp-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loews-js": () => import("../src/pages/loews.js" /* webpackChunkName: "component---src-pages-loews-js" */),
  "component---src-pages-nike-js": () => import("../src/pages/nike.js" /* webpackChunkName: "component---src-pages-nike-js" */)
}

